export const defaultConfig = {
  // For Devlopment
    baseAPIUrl:"https://halal.etrueconcept.com/",

    // For lOcal server
    //baseAPIUrl:"http://192.168.0.3:7600/",
}

export const ImageUrl = {
 
// For Devlopment
  RestaurantMenu:"https://halal.etrueconcept.com/images/restaurantMenu/",
  RestaurantImage:"https://halal.etrueconcept.com/images/restaurants/",
  ProductImages:"https://halal.etrueconcept.com/images/productImages/",
   UserImage:"https://halal.etrueconcept.com/images/userProfile/",
   RestaurantServices:"https://halal.etrueconcept.com/images/restaurantService/"


     // For Local
    //  RestaurantMenu:"http://192.168.0.3:7600/images/restaurantMenu/",
    //  RestaurantImage:"http://192.168.0.3:7600/images/restaurants/",
    //  ProductImages:"http://192.168.0.3:7600/images/productImages/",
    //  UserImage:"http://192.168.0.3:7600/images/userProfile/",
    // RestaurantServices:"http://192.168.0.3:7600/images/restaurantService/"
}

